import React, { useEffect, useState } from 'react';
import API from '../../config/config';

const DiscountBanner = (props: any) => {
  const windowSize = useWindowSize();
  function useWindowSize() {
    const [dimension, setDimensions] = useState({
      height: undefined,
      width: undefined
    });

    useEffect(() => {
      const handleResize = () => {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        });
      };

      window.addEventListener('resize', handleResize);
      handleResize()
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return dimension
  }

  return (
    <div className="container">
      <div className="container-discountBanner-content">
        <img
          className="img-discount"
          src={API.baseUrl + props.data?.badge.url}
          alt={props.data?.badge.alternativeText}
        />
        <div className={windowSize.width >= 780 ? 'show' : 'hide'}>
          <h2 className="h2-heading-discount">{props.data?.title}</h2>
        </div>
        <div className={windowSize.width < 780 ? 'show' : 'hide'}>
          <h2 className="h2-heading-discount-mobile">{props.data?.mobileTitle}</h2>
        </div>
        <h6 className="h6-heading-discount">{props.data?.shortDescription}</h6>
        <h4>
          <a href={props.data?.LearnMore.url} className="h4-heading-discount military-pop">
            {props.data?.LearnMore.text}
          </a>
        </h4>
      </div>
    </div>
  );
};

export default DiscountBanner;
