import React, {useEffect, useRef} from 'react';
import API from '../../config/config';
import RatingStarts from '../../components/RatingStars/RatingStarts';

const TransparentRating = (props: any) => {
  const ref = useRef(null)
  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        if (window.innerWidth > 960) {
          ref.current.style.backgroundImage = `url(${props.data?.cover?.url})`;
        } else {
          ref.current.style.backgroundImage = `url(${props.data?.mobileCover?.url})`;
        }
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="container-fluid container-fluid-sizing">
      <div
        className="Scroller-Effect"
        ref={ref}
      >
        <div className="opacity-overlay" />
        <div className="container">
          <div className="container-background-content">
            <div>
              <h2 className="h2-heading">{props.data?.title}</h2>
              {/* <RatingStarts ratingValue={props.data.rate} className="transpertant-rating" /> */}
              <h5 className="h5-heading">
               {props.data?.reviewText}
              </h5>
              <h6 className="h6-heading">
                <span className="h6-heading-bold">{props.data?.name}</span> {props.data?.place}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransparentRating;
