import React, { useEffect, useState } from "react";
import Cards from "../components/CardImageGroup/TextImageCardGroup";
import Player from "../components/VideoPlayer/Player";
import VideoWidget from "../components/VideoImageSection/VideoImageSectionGroup";
import CardCarouselRatingGroup from "../components/CardCarouselRatingWidget/CardCarouselRatingGroup";
import TransparentRating from "../components/TransparentRating/TransparentRating";
import DiscountBanner from "../components/DiscountBanner/DiscountBanner";
import DownloadSection from "../components/DownloadSection/DownloadSection";
import TrainingSection from "../components/TrainingSection/TrainingSection";
import CardCarouselInstagram from "../components/CardCarouselInstagram/CardCarouselInstagram";
import OfferWidget from "../components/OfferWidget/OfferWidget";
import FooterComp from "../components/Footer/Footer";
import HeaderComp from "../components/Header/Header";
import config from "../config/config";
import ProductCardWidget from "../components/ProductCardWidget/ProductCardWidget";
import PromotionalBanner from "../components/PromotionalBanner/PromotionalBanner";
import MilitaryDiscount from "../components/MilitaryDiscount/MilitaryDiscount";
import TestimonialRatings from "../components/TestimonialRatings/TestimonialRatings";
import SetHeaders from "../config/SetHeaders";
import seo from "../config/seo";
import axios from "axios";
import Head from "next/head";
import { useRouter } from 'next/router'



const HomePageContainer = ({ Header, Body, Footer, response }: any) => {
  const windowSize = useWindowSize();
  const router = useRouter()
  useEffect(() => {
    const { query : { post_type }} = router
    if(post_type == 'wc_product_tab' ){
        router.push('/no-warranty-application')
    }
  });
  function useWindowSize() {
    const [dimension, setDimensions] = useState({
      height: undefined,
      width: undefined,
    });
  
    useEffect(() => {
      const handleResize = () => {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        });
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return dimension;
  }

  const components = (typeName: any, compData: any, key: any) => {
    switch (typeName) {
      case "page.promotional-banner":
        if (compData.ActivePromo) {
          return <PromotionalBanner key={key} data={compData}/>
        } else {
          return
        }

        
      case "page.video-player-container":
        return (
          <div key={key} className="video-container">
            <Player data={compData} type={true} landing={true} />
          </div>
        );
      case "page.video-player-card-group":
        return <VideoWidget key={key} data={compData} />;
      case "page.carg-image-group":
        return (
          <div key={key} className="textimagestyle">
            <Cards data={compData} />
          </div>
        );
      case "product.review-widget":
        return <TestimonialRatings data={compData} key={key} landing={false} />;
      case "widget.rating-container":
        return <CardCarouselRatingGroup starshow={true} data={compData} key={key}/>;
      case "widget.first-component":
        return <TransparentRating data={compData} key={key} />;
      case "widget.download-widget":
        return <DownloadSection key={key} data={compData} />;
      case "body.training-section":
         return <TrainingSection key={key} data={compData} />
      case "widget.instagram-widget":
        return <CardCarouselInstagram key={key} data={compData} />;
      case "body.discount-widget":
        return <DiscountBanner key={key} data={compData} />;
      case "body.offer-widget":
        return <OfferWidget key={key} data={compData} />;
      case "page.homepage-product-card-widget":
        return <ProductCardWidget key={key} data={compData} />;
      case "info.info-component":
        return <MilitaryDiscount key={key} data={compData} isBadge={false} />;
      case "page.rating-mobile-view":
        return;
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      {response && !response.Seo && (
        <Head>
          <title>{seo.home}</title>
        </Head>
      )}
      {response && <SetHeaders HEADERS={response} />}
      {Header && <HeaderComp HeaderValue={Header} />}
      <div className="app-body">
        {Body &&
          Body.map((data: any, i: number) => {
            return components(data.__component, data, i);
          })}
      </div>
      
      {Footer && <FooterComp data={Footer} />}
    </React.Fragment>
  );
};

export async function getStaticProps() {
  const { data } = await axios.get(config.endpoints.homepage); 
  return {
    props: {
      Header: data.Header,
      Body: data.Body,
      Footer: data.Footer.Footer,
      response: data,
    },
    revalidate: 3600
  
  };
}

export default HomePageContainer;
