import React from 'react';
import { Row, Col } from 'react-bootstrap';
import API from '../../config/config';

const DownloadSection = (props: any) => {
  return (
    <React.Fragment>
      <div className={'desktop-view-download-section'}>
        <div className="container-fluid">
          <div className="downloadsection-container">
            <Row xs="1" lg="2" md="1">
              <Col xs={{ order: 1 }}>
                <div className="download-container-left">
                  <h2 className="h2-downloadsection-content">{props.data.title}</h2>
                  <div className="image-app-container">
                    <a href={props.data.IosDownloadButton.url} target="_blank" rel="noopener noreferrer">
                      <img
                        aria-label={props.data.IosDownloadButton.icon.url} 
                        src={API.baseUrl + props.data.IosDownloadButton.icon.url}
                        alt="iosImage"
                        className="img-app-link"
                      />
                    </a>
                    <a href={props.data.AndroidDownloadButton.url} target="_blank" rel="noopener noreferrer">
                      <img
                      aria-label={props.data.AndroidDownloadButton.icon.url}
                        src={API.baseUrl + props.data.AndroidDownloadButton.icon.url}
                        className="img-app-link"
                        alt={props.data.AndroidDownloadButton.icon}
                      />
                    </a>
                  </div>
                  <h6 className="h6-downloadsection-content">{props.data.description}</h6>
                  <div className="image-social-container">
                    {props.data.SocialMediaIcon.map((item: any, i: number) => {
                      return (
                        <a key={i} href={item.url} target="_blank" rel="noopener noreferrer">
                          <img
                          aria-label={item.url}
                            className="img-social-download"
                            src={API.baseUrl + item.icon.url}
                            alt={item.icon.alternativeText}
                          />
                        </a>
                      );
                    })}
                  </div>

                  <div>
                  </div>
                </div>
              </Col>

              <Col xs={{ order: 2 }}>
                <div className="download-container-right">
                  <img
                    className="img-mobile-download"
                    src={API.baseUrl + props.data.image.url}
                    alt={props.data.image.alternativeText}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className={'mobile-view-download-section'}>
        <div className="downloadsection-background-mobile">
          <div className="container-fluid">
            <div className="downloadsection-container">
              <Row xs="1" lg="2" md="2">
                <Col xs={{ order: 3 }}>
                  <div className="download-container-left">
                    <h2 className="h2-downloadsection-content">{props.data.title}</h2>
                    <div className="image-app-container">
                      <a href={props.data.IosDownloadButton.url} target="_blank" rel="noopener noreferrer">
                        <img
                        aria-label={props.data.IosDownloadButton.icon.url}
                          src={API.baseUrl + props.data.IosDownloadButton.icon.url}
                          alt="iosImage"
                          className="img-app-link"
                        />
                      </a>
                      <a href={props.data.AndroidDownloadButton.url} target="_blank" rel="noopener noreferrer">
                        <img
                        aria-label={props.data.AndroidDownloadButton.icon.url}
                          src={API.baseUrl + props.data.AndroidDownloadButton.icon.url}
                          className="img-app-link"
                          alt={props.data.AndroidDownloadButton.icon}
                        />
                      </a>
                    </div>
                    <h6 className="h6-downloadsection-content">{props.data.description}</h6>
                    <div className="image-social-container">
                      {props.data.SocialMediaIcon.map((item: any, i: number) => {
                        return (
                          <a key={i} href={item.url} target="_blank" rel="noopener noreferrer">
                            <img
                              className="img-social-download"
                              aria-label={item.icon.url}
                              src={API.baseUrl + item.icon.url}
                              alt={item.icon.alternativeText}
                            />
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </Col>
                <Col xs={{ order: 1 }}>
                  <div className="download-container-left">
                    <div>
                      <h6 className="h6-downloadsection-content">
                        <strong>{props.data.PoweredByWidget.title}</strong>
                      </h6>
                      <div className="flex-box">
                        {props.data.PoweredByWidget.CompanyLogos.map((item: any, i: number) => {
                          return (
                            <div
                              key={i}
                              style={{
                                backgroundImage: 'url(' + API.baseUrl + item.logo.url + ')'
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={{ order: 2 }}>
                  <div className="download-container-right">
                    <img
                      className="img-mobile-download"
                      src={API.baseUrl + props.data.image.url}
                      alt={props.data.image.alternativeText}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DownloadSection;
