import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CardItem from './TextImageCardItem';

const Cards = (props: any) => {
  return (
    <Container className="card-img-text-container">
      <Row xs="1" xl="3" lg="3" md="1" className="center-ing">
        {props.data.CardImageWidget.map((item: any, i: any) => {
          return (
            <Col key={i} className="col-height">
              <CardItem item={item} />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default Cards;
