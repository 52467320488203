import CardCarouselRating from './CardCarouselRating';
import React from 'react';

const CardCarouselRatingGroup = (props: any) => {
  return (
    <div className="background-group-color-carousal">
      <div className="container ">
        <div className={props.starshow === false ? "cardgroup-img-show" : "cardgroup-img-hidden"} >
          <h1 className="cardCarouselRating-h1">OUR CUSTOMERS LOVE NEXERSYS</h1>
        </div>
        <CardCarouselRating starshow={props.starshow} data={props.data} />
      </div>
    </div>
  );
};

export default CardCarouselRatingGroup;
