import React from 'react';
import API from '../../config/config';

const CardItem = (props: any) => {
  return (
    <div className="col-image-nx">
      <div
        className={'img-container col-img img-border-radius'}
        style={{
          backgroundImage: 'url(' + API.baseUrl + props.item.cover.url + ')'
        }}
      >
        <div className="col-text-title">
          <h2>{props.item.title}</h2>
        </div>
      </div>

      <div className="img-overlay">
        <div className="col-text-des ">
          <h2>{props.item.title}</h2>
          <h6>{props.item.hoverDescription}</h6>
        </div>
      </div>
    </div>
  );
};

export default CardItem;
