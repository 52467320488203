import React from 'react';
import { Card } from 'react-bootstrap';
import API from '../../config/config';
import RatingStars from '../RatingStars/RatingStarts';
import Slider from 'react-slick';

const CardCarouselRating = (props: any) => {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 20000,
        settings: {
          slidesToShow: props.data.numberOfCards,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };
  return (
    <React.Fragment>
      <div className="rating-carousel-container">
        <Slider {...settings}>
          {props.data.RatingCarousal.map((item: any, i: any) => {
            return (
              <div key={i} className="card-carousel-each-card">
                <Card className="whole-card-rating">
                  <Card.Body
                    className="card-rating-body"
                    style={{
                      backgroundImage: `url(${item.backgroundImage ? API.baseUrl + item.backgroundImage.url : ''})`
                    }}
                  >
                    {/* {props.starshow && <RatingStars ratingValue={item.rating} className="carousal-star" />} */}
                    <Card.Text className="card-rating-body-text">{item.review}</Card.Text>
                  </Card.Body>
                  <div className="card-rating-footer">
                    <span className="testimonial__name">{item.name}</span>
                    <span className="testimonial__title">{item.place}</span>
                  </div>
                </Card>
              </div>
            );
          })}
        </Slider>
      </div>
    </React.Fragment>
  );
};

export default CardCarouselRating;
