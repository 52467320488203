import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Container,
  Accordion,
  AccordionContext,
} from "react-bootstrap";
import color from "../../assets/colors";
import icons from "../../assets/icons/icons";
import Link from "next/link";

const ProductCardWidget = ({ data }) => {
  const windowSize = useWindowSize();
  function useWindowSize() {
    const [dimension, setDimensions] = useState({
      height: undefined,
      width: undefined,
    });

    useEffect(() => {
      const handleResize = () => {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        });
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return dimension;
  }
  const CustomToggle = ({ eventKey }: any) => {
    const currentEventKey = React.useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === eventKey;
    if (isCurrentEventKey) {
      return icons.negetive(color.$black, "m-r-10 ");
    } else {
      return icons.plus(color.$black, "m-r-10");
    }
  };

  return (
    <div className="app-product-card">
      <div className={`${windowSize.width > 951 ? `background-group-color-black` : `background-group-color` }`}>
        {windowSize.width > 981 && (
          <Container style={{ maxWidth: 1300 }}>
            {data && data?.productInfo && data.productInfo?.length > 0 && (
              <Row xl={3} md={2} sm={1} xs={1} className="app-product-cards">
                {data.productInfo?.map((cardItems: any, i: number) => {
                  return (
                    <Col className="column-card" key={i}>
                      <Card className="card-body-whole">
                        <Card.Body className="card-body-padding">
                          <div className="product-left-element">
                            <Card.Title>
                              <div className="title-container">
                                <div
                                  className="card-img-size"
                                  style={{
                                    backgroundImage: `url(${cardItems.logo?.url})`,
                                  }}
                                />
                                {cardItems.name === "Cross body trainer Pro" && (
                                  <p className="cardItem-sub-title">PRO</p>
                                )}
                                {cardItems.name ===
                                  "Cross body trainer Home" && <p className="cardItem-sub-title">HOME</p>}
                              </div>
                            </Card.Title>
                            {/* {cardItems.displayTitle && <Card.Title>{cardItems.displayTitle}</Card.Title>}
                            <Card.Text
                              className="text-card-product"
                              dangerouslySetInnerHTML={{ __html: cardItems.description }}
                            /> */}
                            <Card.Text as="div">
                              <div className="carditem-price">
                                <h3>${cardItems.price}</h3>
                                <p>or as low as</p>
                                <h4>${cardItems.affirmPrice}/mo</h4>
                                <p>with <img className="affirm-logo" src="https://web.cdn.nexersys.com/assets/blue_logo_transparent_bg_ec75b82bf6.png" alt="affirm logo" /></p> 
                              </div>
                              <span className="carditem-descp">
                                {cardItems?.description}
                              </span>
                            </Card.Text>
                            <Button
                              className="button-card-product"
                              aria-hidden="true"
                              tabIndex={-1}
                            >
                              <Link href={`${cardItems.buttonRedirectUrl}`}>
                                <a
                                  className="product-button-text"
                                  draggable={false}
                                  tabIndex={-1}
                                >
                                  learn more
                                </a>
                              </Link>
                            </Button>
                          </div>
                          <div
                            className="product-right-element"
                            style={{
                              backgroundImage: `url(${cardItems.image?.url})`,
                            }}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            )}
          </Container>
        )}
        {windowSize.width <= 981 && (
          <>
          <h2 className="view-prod-accordian">View Our Products </h2>
          <div className="app-product-accordian">
            <Accordion defaultActiveKey="1">
              {data && data?.productInfo && data.productInfo?.length > 0 && data.productInfo?.map((cardItems: any, i: number) => {
                return (
                  cardItems && (
                    <Card key={i} style={{borderLeft: "none"}}>
                      <Accordion.Toggle eventKey={`${i + 1}`} as={Card.Header} className="capitalize-name bg-color-accordion">
                        <CustomToggle eventKey={`${i + 1}`} />
                        {cardItems.name}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={`${i + 1}`}>
                        <React.Fragment>
                          {cardItems && (
                            <div className="app-img-width">
                              <img
                                src={cardItems.image?.url}
                                alt={
                                  ""
                                }
                              />
                            </div>
                          )}
                          <div className="app-prod-details">
                            <h3 className="app-prod-title capitalize-name">
                              {cardItems.name}
                            </h3>
                            <div className="carditem-price mobile-price">
                                <h3>${cardItems.price}</h3>
                                <p>or as low as</p>
                                <h4>${cardItems.affirmPrice}/mo</h4>
                                <p>with <img className="affirm-logo affirm-mb" src="https://web.cdn.nexersys.com/assets/Affirm_logo_white_55bee80414.svg" alt="affirm logo" /></p> 
                              </div>
                            <h6
                              className="app-prod-desc"
                            >
                              {cardItems.description}
                              </h6>
                            <div style={{marginTop: 35, marginBottom: 25}}>
                            <Link href={`${cardItems.buttonRedirectUrl}`}>
                              <a
                                draggable={false}
                                className="button-card-product"
                              >
                                learn more
                              </a>
                            </Link>
                            </div>
                          </div>
                        </React.Fragment>
                      </Accordion.Collapse>
                    </Card>
                  )
                );
              })}
            </Accordion>
          </div>
        </>
        )}
      </div>
    </div>
  );
};

export default ProductCardWidget;
